import React, { useState, useEffect } from "react";
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/react";
import { withRouter } from "react-router";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import trash from "../../images/can.png";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import DesktopHeader from "../../components/DesktopHeader";
import
{
  getCityInLang,
  getLangLabel,
  getLangState,
} from "../../utility/languageTranslator";
import FooterMenu from "../../components/FooterMenu";
// import { FirebaseCrashlytics } from "@capacitor-firebase/crashlytics";

const Downloads = ({ history }) =>
{
  const [downloadcityList, setDownloadCityList] = useState([]);
  const [routeName, setRouteName] = useState("");
  const [langCode, setlangCode] = useState("en");

  useEffect(() =>
  {
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
    const fetchData = async () =>
    {
      const dataArr = JSON.parse(window.localStorage.getItem("myDownloads"));
      console.log("Data from localStorage:", dataArr);
      // await FirebaseCrashlytics.log("Data fetched from localStorage at downloads:", dataArr);
      if (dataArr)
      {
        setDownloadCityList(dataArr);
      } else
      {
        console.log("No data found in localStorage for 'myDownloads'");
        // await FirebaseCrashlytics.log("No data found in localStorage for 'myDownloads'");
      }

    };
    
    fetchData();
  }, []);

  const handleDelete = async (index, item) =>
  {
    console.log(index, item);
    const updatedList = [...downloadcityList];
    updatedList.splice(index, 1); // Remove one item at the specified index
    console.log(updatedList);
    setDownloadCityList(updatedList);
    // Update localStorage if necessary
    localStorage.setItem("myDownloads", JSON.stringify(updatedList));
    // await FirebaseCrashlytics.log({message:"Data deleted from localStorage at downloads.",updatedList});
    alert(getLangLabel(langCode)?.dataDeleted);
  };
  const setAnalyticsScreen = async () =>
  {
    await FirebaseAnalytics.logEvent({
      name: "screen_view",
      params: {
        screen_name: routeName,
        screen_class: "Bookmarks",
      },
    });
  };
  useEffect(() =>
  {
    const pathname = history.location.pathname;
    const trimmedPathname = pathname.slice(1);
    console.log("routeName===", trimmedPathname);
    setRouteName(trimmedPathname);
    if (trimmedPathname !== "")
    {
      setAnalyticsScreen();
    }
  }, [routeName]);

  const handleGoBack = () =>
  {
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader style={{ "--background": "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon className="" onClick={handleGoBack} />
              <h4 className="title-txt">{getLangLabel(langCode)?.downloads}</h4>
              <ArrowBackIosNewRoundedIcon className="visibilityHidden" />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent className="backgroundGraphics">
        <div className="flex-head">
          <div className="container">
            <h4 className="inner-title">{getLangLabel(langCode)?.downloads}</h4>
          </div>
        </div>
        <div className="city-list-box">
          <div className="container">
            {downloadcityList.length === 0 ? (
              <h4 style={{ textAlign: "center" }}>
                {getLangLabel(langCode)?.notDownloads}
              </h4>
            ) : (
              <div className="bg-grey">
                <div className="placeList">
                  <ul>
                    {downloadcityList.map((item, index) => (
                      <li key={item.id} style={{ padding: "0", paddingLeft: "20px" }}>
                        <label>{index + 1}.</label>
                        <label
                          onClick={async () =>
                          {
                            localStorage.setItem(
                              "placeDetails",
                              JSON.stringify(item)
                            );
                            if (item?.type === "district"){
                              localStorage.setItem("bookmarkPlaceToView", JSON.stringify(item));
                              history.push(`/placeinformation`);}
                            else {
                              localStorage.setItem("bookmarkPlaceToView", JSON.stringify(item));
                              history.push(`/particularplaceinformation`);}
                          }} style={{ padding: "16px 20px", flex: "1" }}
                        >
                          {item?.type === "district"
                            ? langCode === "hi"
                              ? `${getCityInLang(item?.city, langCode)} (${getLangState()?.[item.state]
                              })`
                              : `${item.city} (${item.state})`
                            : langCode === "hi"
                              ? item?.name_lang?.hi
                              : item?.name}
                        </label>
                        <span style={{ marginLeft: "auto", padding: "16px 20px", minWidth: "70px", width: "70px" }}>
                          <img
                            src={trash}
                            style={{ width: "30px" }}
                            onClick={() => handleDelete(index, item)}
                            alt=""
                          />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </IonContent>
      <div style={{ textAlign: "center", background: "#fff" }}>
        <p>{getLangLabel(langCode)?.bookmarked}</p>
      </div>
      <FooterMenu history={history} langCode={langCode} />
    </IonPage>
  );
};

export default withRouter(Downloads);
