import React from "react";
import ReactDOM from "react-dom";
import { Capacitor } from '@capacitor/core';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IonReactRouter } from "@ionic/react-router";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";


// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCuwRQflf0HFg57P3W6G1_Wd_G0xDvBcr8",
  authDomain: "travel-app-35c54.firebaseapp.com",
  projectId: "travel-app-35c54",
  storageBucket: "travel-app-35c54.appspot.com",
  messagingSenderId: "583669625898",
  appId: "1:583669625898:web:f8db701005b393db9ebf53",
  measurementId: "G-C01QVJ5TBK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
let messaging;
let VAPID_KEY;

// Conditional import and initialization for Firebase Analytics on native platforms
if (Capacitor.isNativePlatform()) {
  import('@capacitor-firebase/analytics').then(({ FirebaseAnalytics }) => {
    FirebaseAnalytics.initialize();
  });
} else {
  // Initialize Firebase Messaging on web platforms
  messaging = getMessaging(app);
  VAPID_KEY = "BBCZTXSM_dK21tGxBMbm74AKuvuC9rWAJxVxTH3N2vtY2R1N2leqmS_X2JTg9vYnBZs5lUGSGRnioNbI5w5jIFM";
  
  // Dynamically import the service worker registration module for web
  import('./serviceWorkerRegistration').then(module => {
    module.unregister();
  });
}

// Initialize Firebase app for compat API
firebase.initializeApp(firebaseConfig);

// Exports
export { auth, messaging, VAPID_KEY };

// Render the React app
ReactDOM.render(
  <React.StrictMode>
    <IonReactRouter>
      <App />
    </IonReactRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// Measure performance in the app
reportWebVitals();
