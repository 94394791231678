import { useState } from "react";
import { getLangLabel } from "../../utility/languageTranslator";
import eyeOpen from "../../images/eye-open.png";
import eyeClose from "../../images/eye-close.png";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { useParams, withRouter } from "react-router";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import { IonAlert } from "@ionic/react";

const VerifyLogin = () => {
    const { token } = useParams();
    const [tokenString, setTokenString] = useState(token);
    const [langCode, setlangCode] = useState("en");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passView, setPassView] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [updatedUser, setUpdatedUser] = useState({});
    const [emailVerification, setEmailVerification] = useState(false);
    var target = "_system";
    var iaboptions = {
        location: "no", //Or 'no'
        hideurlbar: "yes", //Or 'no'
        hidenavigationbuttons: "yes",
        fullscreen: "yes",
        toolbarcolor: "#000000",
        closebuttoncaption: "exit",
        clearcache: "yes",
    };

    const validateEmail = (email) => {
        if (!email) {
            setEmailError(getLangLabel(langCode)?.emailRequired);
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        if (!password) {
            setPasswordError(getLangLabel(langCode)?.passRequired);
            return false;
        } else if (password.length < 6) {
            setPasswordError(getLangLabel(langCode)?.passMust);
            return false;
        }
        return true;
    }

    const handleLogin = async () => {
        setEmailError("");
        setPasswordError("");
    
        try {
            // Make the request to validate the token
            const tokenValidationResponse = await HTTP_CLIENT(APIFY("/users/validate-token"), "POST", {
                token: tokenString,
            });
    
            console.log(tokenValidationResponse.message);
    
            // If token validation fails, stop the login process
            if (tokenValidationResponse.status === 502) {
                alert("Token already used.");
                return;  // Exit the function early if token is already used
            }
    
            // Proceed with email and password validation
            await validateEmail(email);
            await validatePassword(password);
    
            // If no errors, proceed with login
            if (!emailError && !passwordError) {
                const loginResponse = await HTTP_CLIENT(APIFY("/users/login"), "POST", {
                    email: email,
                    password: password,
                });
    
                const user = loginResponse?.data;
                console.log(user);
    
                // Mark email as verified
                user.emailverified = true;
                try {
                    const updateResponse = await HTTP_CLIENT(APIFY(`/users/${user.id}`), "PUT", user);
                    alert(getLangLabel(langCode)?.emailVerified);
                    setEmailVerification(true);
                } catch (err) {
                    console.error("Update failed:", err);
                }
            }
        } catch (error) {
            console.error("Login failed:", error);
    
            // Check if it's a network or server error
            if (error.response) {
                if (error.response.status === 502) {
                    // 502 Bad Gateway
                    alert("Token already used.");
                } else {
                    // Handle other errors
                    alert(error.response.data.message || "An error occurred.");
                }
            } else if (error.request) {
                // Handle cases when no response is received (network error)
                alert("Network error: Unable to reach the server. Please check your connection and try again.");
            } else {
                // Handle other errors like invalid token format
                alert(error.message || "An error occurred");
            }
        }
    };
    
    
    const togglePassView = () => {
        setPassView(!passView);
    };
    return <div className="tabContent">
        <div className="verifyLogin">
            <h4 className="fields">Login</h4>
            <div className="fields">
                <label>{getLangLabel(langCode)?.email}</label>
                <input
                    type="email"
                    placeholder={getLangLabel(langCode)?.yourEmailP}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && (
                    <p className="error-message">{emailError}</p>
                )}
            </div>
            <div className="fields">
                <label>{getLangLabel(langCode)?.password}</label>
                <div className="password-container">
                    <input
                        type={passView ? "text" : "password"}
                        placeholder={
                            getLangLabel(langCode)?.yourPasswordP
                        }
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <span onClick={togglePassView}>
                        <img
                            alt=""
                            src={passView ? eyeClose : eyeOpen}
                            width={20}
                        />
                    </span>
                    {passwordError && (
                        <p className="error-message">{passwordError}</p>
                    )}
                </div>
            </div>
            {/* <div className="forgotPass">Forgot Password?</div> */}

            <div className="submitBtn" onClick={handleLogin}>
                <button> {getLangLabel(langCode)?.logIn}</button>
            </div>
            <div className="info">
                <p>
                    {getLangLabel(langCode)?.loginP1}{" "}
                    <span
                        onClick={async () => {
                            let url =
                                "https://chalobharatdekhen.com/terms-and-conditions";
                            InAppBrowser.create(url, target, iaboptions);
                        }}
                    >
                        {getLangLabel(langCode)?.loginP2}
                    </span>
                    {getLangLabel(langCode)?.loginP3}
                    <span
                        onClick={async () => {
                            let url =
                                "https://chalobharatdekhen.com/privacy-policy";
                            InAppBrowser.create(url, target, iaboptions);
                        }}
                    >
                        {getLangLabel(langCode)?.loginP4}
                    </span>
                </p>
            </div>
            <IonAlert isOpen={emailVerification} onDidDismiss={() => setEmailVerification(false)}>Email Verified Re-Open App</IonAlert>
        </div>
    </div>
};

export default withRouter(VerifyLogin); 